<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_service") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit(true)"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form
      ref="form"
      :model="form"
      v-loading="loadingData"
      :rules="rules"
      class="aticler_m"
    >
      <el-row :gutter="20">
        <el-col :sm="12">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input
              v-model="form.name"
              autocomplete="off"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-col>

        <!-- end col -->
        <el-col :sm="12">
          <el-form-item :label="columns.parent_id.title" prop="parent_id">
            <select-service
              :service_id="form.parent_id"
              v-model="form.parent_id"
              @input="getValue"
            ></select-service>
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :sm="12">
          <el-form-item :label="columns.code.title" prop="code">
            <el-input
              v-model="form.code"
              autocomplete="off"
              size="medium"
            ></el-input>
          </el-form-item>
        </el-col>
        <!-- end col -->

        <el-col :sm="12">
          <el-form-item :label="columns.price.title">
            <div class="el-input el-input--small">
              <el-input
                  type="text"
                  v-model="form.price"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.discount_price.title">
            <div class="el-input el-input--small">
              <el-input
                type="text"
                v-model="form.discount_price"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="12">
          <el-form-item :label="columns.service_category.title">
            <select-service-category
                :category_id="form.category_id"
                v-model="form.category_id"
                @input="getValue"
            ></select-service-category>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item :label="columns.life_time.title">
            <div class="el-input el-input--small">
              <el-input
                type="number"
                step="any"
                :min="0"
                :max="100"
                size="medium"
                v-model="form.life_time"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>
        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                  v-model="form.unnecessary_enclosure"
                  :label="$t('message.unnecessary_enclosure')"
                  border
                  size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_parent"
                :label="$t('message.parent')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_phy"
                :label="$t('message.procedural')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_laboratory"
                :label="$t('message.laboratory')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.letterhead"
                :label="$t('message.letterhead')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <el-form-item>
            <div class="el-input el-input--small ser_chek">
              <el-checkbox
                v-model="form.is_report"
                :label="$t('message.reports')"
                border
                size="medium"
              ></el-checkbox>
            </div>
          </el-form-item>
        </el-col>

        <el-col :sm="4">
          <div class="block">
            <div>{{ $t("message.color") }}</div>
            <el-color-picker
              v-loading="loadingData"
              v-model="color"
            ></el-color-picker>
          </div>
        </el-col>
      </el-row>
      <!-- end row -->
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selectService from "@/components/inventory-select/select-service";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
import {correctInputNumber} from "@/filters";
import SelectServiceCategory from "@/components/inventory-select/select-service-category.vue";
export default {
  mixins: [form, drawerChild],
  components: {
    SelectServiceCategory,
    selectService,
  },
  data() {
    return {
      selected_parent_id: [],
      loadingData: false,
      color: null,
      service_process: [],
      options: [
        { value: "cash", label: "Сумма" },
        { value: "percent", label: "Процент" },
      ],
    };
  },
  watch: {
    'form.price': function(newPrice) {
      this.correctInputNumber('form', 'price', newPrice);
    },
    'form.discount_price': function(newPrice) {
      this.correctInputNumber('form', 'discount_price', newPrice);
    },
  },
  computed: {
    ...mapGetters({
      services: "services/list",
      rules: "services/rules",
      //   model: "services/model",
      columns: "services/columns",
      categories: "categoryServices/list",
      processes: "processes/list",
      // money: "money",
    }),
  },
  async mounted() {
    if (this.categories && this.categories.length === 0)
      await this.loadCategories();
    if (this.services && this.services.length === 0) await this.loadServices();

    if (this.processes && this.processes.length === 0)
      await this.loadProcesses();
    this.service_process = this.processes;
  },
  async created() {
    await this.fetchData();
  },

  methods: {
    correctInputNumber,
    ...mapActions({
      save: "services/store",
      loadProcesses: "processes/index",
      loadCategories: "categoryServices/index",
      loadServices: "services/index",
      colorServices: "services/color",
      loadColor: "services/show",
    }),
    async afterOpened() {
      if (this.categories && this.categories.length === 0)
        await this.loadCategories();
      if (this.services && this.services.length === 0)
        await this.loadServices();

      //   if (this.processes && this.processes.length === 0)
      await this.loadProcesses();
      await this.fetchData();
      this.service_process = this.processes;
    },
    getValue() {
      this.loadingData = true;
      if (this.selected_parent_id != []) {
        this.loadingData = false;
        this.color = this.selected_parent_id.filter(
          (val) => val.id == this.form.parent_id
        )[0].color;
      }
    },
    fetchData() {
      this.loadingData = true;
      const query = { selected_relation: true };
      this.colorServices(query)
        .then((res) => {
          this.loadingData = false;
          this.selected_parent_id = res.data.services.data;
        })
        .catch((err) => {});
    },
    afterClosed() {},
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.color = this.color;
          this.form.price = this.strToInt(this.form.price);
          this.form.discount_price = this.strToInt(this.form.discount_price);
          this.form.processes = this.service_process.filter(
            (val) => val.check == true
          );
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              this.parent().listChanged();
              this.resetForm("form");
              this.color = null;
              this.service_process = [];
              this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
            });
        }
      });
    },
    resetForm(formName) {
      this.close();
      this.$refs[formName].resetFields();
      this.reloadForm();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
    strToInt(val) {
      if (typeof(val) === 'string') {
        return parseFloat(val.replace(/\s/g, ''));
      }
      return val;
    }
  },
};
</script>

<style >
.khan_se .el-select-dropdown__item {
  width: 600px;
  overflow: initial;
  height: inherit;
  white-space: initial;
}

.ser_chek {
  margin-top: 18px;
}

.ser_chek label.el-checkbox {
  width: 100%;
}
.servis-pratses {
  background: #e1e8f1;
  padding: 10px;
  padding-bottom: 3px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
}
.servis-pratses h5 {
  min-width: 250px;
  margin-left: 5px;
  font-size: 14px;
}
</style>